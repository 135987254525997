import i18next from "i18next";
import React, { useState } from "react";
import { Accordion, Card, Col, Row } from "react-bootstrap";
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { SzIcon } from "react-theme-components";
import { bindActionCreators } from "redux";
import { icons } from "../../config";
import { SearchActions } from "../../store/redux/actions";
import DataNotReady from "../Utils/messages/dataNotReady";
import PdfViewer from "../Utils/pdfViewer";
import "./detail.scss";

interface IDocumentsProps {
  objectType: string;
  documents: Array<{
    date: string,
    stream_name: string;
  }>;
  siteDocuments: any;
  vicrDocuments: any;
  itvDocuments: any;
  getDocumentDownloadUrl: (downloadUrl: string) => any;
  industriel?:any
}

const Documents = (props: IDocumentsProps) => {
  const { t } = useTranslation();
  const { getDocumentDownloadUrl } = props;
  const [titlePDF, setTitlePDF] = useState("");
  const [uriPDF, setUriPDF] = useState("");
  const [documentLength, setDocumentLength] = useState(0);
  const [PDFViewerIsActive, setPDFViewerIsActive] = useState(false);
  const [documentOpen, setDocumentOpen] = useState<boolean>(true);
  const [connectionDocumentOpen, setConnectionDocumentOpen] = useState<boolean>(true);
  const [vicrDocumentOpen, setVicrDocumentOpen] = useState<boolean>(true);
  const [otherDocumentOpen, setOtherDocumentOpen] = useState<boolean>(true);
  const [activeKey, setActiveKey] = useState("");
  const [itvDocumentOpen, setItvDocumentOpen] = useState<boolean>(true);

  const otherDocumentClassified: any = [];
  const otherDocumentType: any = [];
  if (props.documents !== undefined && props.documents.length !== 0) {
    props.documents.map((document: any) => {
      let type = document["gv:descriptionEM"];
      if (!type || type === "") {
        type = "Autres";
      }
      if (!otherDocumentType.includes(type)) {
        otherDocumentType.push(type);
      }
      otherDocumentClassified[type] = otherDocumentClassified[type] ?? [];
      otherDocumentClassified[type].push(document);
    });
  }

  const showPDFViewer = (document: any) => {
    setTitlePDF(getDocumentTitle(document));
    setUriPDF(getDownloadUrl(document["gaia:downloadUrl"]));
    setPDFViewerIsActive(!PDFViewerIsActive);
    setDocumentLength(document["cmis:contentStreamLength"]);
    ReactGA.event({ action: `Pdf viewer: ${getDocumentTitle(document)}`, category: "PDF" });
  };

  const isPDF = (uri: string, mimeType: string): boolean => {
    return (
      !!getDownloadUrl(uri) &&
      mimeType === "application/pdf"
    );
  };

  const getDownloadUrl = (downloadUrl: string) => {
    return getDocumentDownloadUrl(downloadUrl).payload;
  };

  const handleClick = (document: any) => {
    if (isPDF(document["gaia:downloadUrl"], document["cmis:contentStreamMimeType"])) {
      showPDFViewer(document);
    } else {
      window.open(getDownloadUrl(document["gaia:downloadUrl"]), "_blank");
    }
  };

  const getFileIcon = (filename: string) => {
    const parts = filename.split(".");
    switch (parts[parts.length - 1]) {
      case "pdf":
        return icons["icon-pdf"];
      case "doc":
      case "docx":
        return icons["icon-doc"];
      case "mp4":
        return icons["icon-itv"];
      default:
        return icons["icon-file"];
    }
  };

  if (props.objectType !== "asset:conduite_de_branchement") {
    return (
      <>
        {PDFViewerIsActive && (
          <PdfViewer
            title={titlePDF}
            uri={uriPDF}
            visible={PDFViewerIsActive}
            handleClosePdfViewer={() => setPDFViewerIsActive(!PDFViewerIsActive)}
            documentLength={documentLength ? documentLength : 0}
          />
        )}
        {   props.itvDocuments &&
            props.itvDocuments.length >0 &&
        <Accordion defaultActiveKey="0">
          <Card className="container-bordered">
            <Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => setDocumentOpen(!documentOpen)}>
              {t("report:documents")}
              {documentOpen ?
                <SzIcon variant="bold" icon="arrow-up-1" /> :
                <SzIcon variant="bold" icon="arrow-down-1" />
              }
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                {   props.objectType !== "asset:regard_de_visite" &&
                    props.itvDocuments &&
                    props.itvDocuments.length >0 &&
                  <Accordion defaultActiveKey="0">
                    <Card className="container-bordered">
                      <Accordion.Toggle
                        as={Card.Header}
                        eventKey="0"
                        onClick={() => setItvDocumentOpen(!itvDocumentOpen)}
                      >
                        {t("report:itvDocuments")}
                        {itvDocumentOpen ?
                          <SzIcon variant="bold" icon="arrow-up-1" /> :
                          <SzIcon variant="bold" icon="arrow-down-1" />
                        }
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body as={Row}>
                          {!props.itvDocuments &&
                            <DataNotReady />
                          }
                          {props.itvDocuments && props.itvDocuments.length === 0 &&
                            <DataNotReady />
                          }
                          {props.itvDocuments && props.itvDocuments.map((doc: any) => {
                            return (
                              <Col
                                onClick={() => handleClick(doc)}
                                sm="3"
                                className="text-center gaia-detail-result--documents-container--doc"
                              >
                                <img
                                  className="gaia-detail-result--documents-container--doc-icon"
                                  alt=""
                                  src={getFileIcon(getDocumentTitle(doc))}
                                />
                                <span className="gaia-detail-result--documents-container--doc-title">
                                  {getDocumentTitle(doc)}
                                </span>
                              </Col>
                            );
                          })
                          }
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                }
                {!props.documents && props.objectType === "asset:regard_de_visite" &&
                  <DataNotReady />
                }
                {props.documents && props.documents.length === 0 && props.objectType === "asset:regard_de_visite" &&
                  <DataNotReady />
                }
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        }
        {   props.vicrDocuments &&
            props.vicrDocuments.length >0 &&
        <Accordion defaultActiveKey="0">
          <Card className="container-bordered">
            <Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => setVicrDocumentOpen(!vicrDocumentOpen)}>
              {t("report:vicrDocuments")}
              {vicrDocumentOpen ?
                <SzIcon variant="bold" icon="arrow-up-1" /> :
                <SzIcon variant="bold" icon="arrow-down-1" />
              }
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body as={Row}>
                {!props.vicrDocuments &&
                  <DataNotReady />
                }
                {props.vicrDocuments && props.vicrDocuments.length === 0 &&
                  <DataNotReady />
                }
                {props.vicrDocuments && props.vicrDocuments.map((doc: any) => {
                  return (
                    <Col
                      onClick={() => handleClick(doc)}
                      sm="3"
                      className="text-center gaia-detail-result--documents-container--doc"
                    >
                      <img
                        className="gaia-detail-result--documents-container--doc-icon"
                        alt=""
                        src={getFileIcon(getDocumentTitle(doc))}
                      />
                      <span className="gaia-detail-result--documents-container--doc-title">
                        {getDocumentTitle(doc)}
                      </span>
                    </Col>
                  );
                })
                }
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        }
        {otherDocumentType !== [] && otherDocumentType.length !== 0 &&
            <Accordion defaultActiveKey="0">
          <Card className="container-bordered">
            <Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => setOtherDocumentOpen(!otherDocumentOpen)}>
              {t("report:otherDocuments")}
              {otherDocumentOpen ?
                <SzIcon variant="bold" icon="arrow-up-1" /> :
                <SzIcon variant="bold" icon="arrow-down-1" />
              }
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                {otherDocumentType !== [] && otherDocumentType.map((type: any) => {
                  const documentClassified = otherDocumentClassified[type];
                  return (
                  <Accordion defaultActiveKey="0">
                    <Card className="container-bordered">
                      <Accordion.Toggle
                        as={Card.Header}
                        eventKey="0"
                        onClick={() => setOtherDocumentOpen(!otherDocumentOpen)}
                      >
                        {type}
                        {otherDocumentOpen ?
                          <SzIcon variant="bold" icon="arrow-up-1" /> :
                          <SzIcon variant="bold" icon="arrow-down-1" />
                        }
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body as={Row}>
                          {documentClassified && documentClassified.map((doc: any) => {
                            return (
                              <Col
                                onClick={() => handleClick(doc)}
                                sm="3"
                                className="text-center gaia-detail-result--documents-container--doc"
                              >
                                <img
                                  className="gaia-detail-result--documents-container--doc-icon"
                                  alt=""
                                  src={getFileIcon(getDocumentTitle(doc))}
                                />
                                <span className="gaia-detail-result--documents-container--doc-title">
                                  {getDocumentTitle(doc)}
                                </span>
                              </Col>
                            );
                          })
                          }
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                  );
                })
                }
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        }
      </>
    );
  } else {
    const siteIndusDocument: {
      visit: any,
      regulation: any,
      couriers: any,
      control: any,
      selfMonitoring: any,
    } = {
      visit: [],
      regulation: [],
      couriers: [],
      control: [],
      selfMonitoring: [],
    };

    const connectionDocument: {
      conformite: any,
      retention: any,
    } = {
      conformite: [],
      retention: [],
    };
    if (props.siteDocuments) {
      siteIndusDocument.visit = props.siteDocuments.filter((item: any) =>
        "Visites -- Bilans" === item["gv:typeDocumentIndustriel"],
      );
      siteIndusDocument.regulation = props.siteDocuments.filter((item: any) =>
        "Réglementation" === item["gv:typeDocumentIndustriel"],
      );
      siteIndusDocument.couriers = props.siteDocuments.filter((item: any) =>
        "Courriers" === item["gv:typeDocumentIndustriel"],
      );
      siteIndusDocument.control = props.siteDocuments.filter((item: any) =>
        "Contrôles inopinés" === item["gv:typeDocumentIndustriel"],
      );
      siteIndusDocument.selfMonitoring = props.siteDocuments.filter((item: any) =>
        "Autosurveillance" === item["gv:typeDocumentIndustriel"],
      );
    }

    connectionDocument.conformite = (props.documents ?? []).filter((item: any) =>
      "Conformité" === item["gv:typeUsagers"],
    );
    connectionDocument.retention = (props.documents ?? []).filter((item: any) =>
      "Rétention parcelle" === item["gv:typeUsagers"],
    );
    return (
      <>
        {PDFViewerIsActive && (
          <PdfViewer
            title={titlePDF}
            uri={uriPDF}
            visible={PDFViewerIsActive}
            handleClosePdfViewer={() => setPDFViewerIsActive(!PDFViewerIsActive)}
            documentLength={documentLength ? documentLength : 0}
          />
        )}

        {props.industriel !== undefined && props.industriel.length > 0 &&
        <Accordion defaultActiveKey="0">
          <Card className="container-bordered">
            <Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => setDocumentOpen(!documentOpen)}>
              {t("report:siteIndustriel.documents")}
              {documentOpen ?
                <SzIcon variant="bold" icon="arrow-up-1" /> :
                <SzIcon variant="bold" icon="arrow-down-1" />
              }
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                {!props.siteDocuments &&
                  <DataNotReady />
                }
                {props.siteDocuments && props.siteDocuments.length === 0 &&
                  <DataNotReady />
                }
                {props.siteDocuments && props.siteDocuments.length > 0 &&
                  <>
                    {Object.entries(siteIndusDocument).map((value: any[], key: any) => {
                      return (
                        <Accordion defaultActiveKey={activeKey}>
                          <Card className="container-bordered">
                            <Accordion.Toggle
                              as={Card.Header}
                              eventKey={`${value[0]}`}
                              onClick={() => activeKey === value[0] ? setActiveKey("") : setActiveKey(value[0])}
                            >
                              {t(`report:siteIndustriel.documentType.${value[0]}`)}
                              {activeKey === value[0] ?
                                <SzIcon variant="bold" icon="arrow-up-1" /> :
                                <SzIcon variant="bold" icon="arrow-down-1" />
                              }
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={`${value[0]}`}>
                              <Card.Body as={Row}>
                                {value[1].length === 0 &&
                                  <DataNotReady />
                                }
                                {value[1].map((doc: any, docKey: any) => {
                                  return (
                                    <Col
                                      onClick={() => handleClick(doc)}
                                      sm="3"
                                      className="text-center gaia-detail-result--documents-container--doc"
                                    >
                                      <img
                                        className="gaia-detail-result--documents-container--doc-icon"
                                        alt=""
                                        src={getFileIcon(getDocumentTitle(doc))}
                                      />
                                      <span className="gaia-detail-result--documents-container--doc-title">
                                        {getDocumentTitle(doc)}
                                      </span>
                                    </Col>
                                  );
                                })
                                }
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        </Accordion>
                      );
                    })
                    }
                  </>
                }
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        }
        <Accordion defaultActiveKey="0">
          <Card className="container-bordered">
            <Accordion.Toggle
              as={Card.Header}
              eventKey="0"
              onClick={() => setConnectionDocumentOpen(!connectionDocumentOpen)}
            >
              {t("report:siteIndustriel.connectionDocuments")}

              {connectionDocumentOpen ?
                <SzIcon variant="bold" icon="arrow-up-1" /> :
                <SzIcon variant="bold" icon="arrow-down-1" />
              }
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                {!props.documents ||  props.documents.length === 0 &&
                  <DataNotReady />
                }
                {props.documents && props.documents.length > 0 &&
                  <>
                    {Object.entries(connectionDocument).map((value: any[], key: any) => {
                      return (
                        <Accordion defaultActiveKey={activeKey}>
                          <Card className="container-bordered">
                            <Accordion.Toggle
                              as={Card.Header}
                              eventKey={`${value[0]}`}
                              onClick={() => activeKey === value[0] ? setActiveKey("") : setActiveKey(value[0])}
                            >
                              {t(`report:connection.documentType.${value[0]}`)}
                              {activeKey === value[0] ?
                                <SzIcon variant="bold" icon="arrow-up-1" /> :
                                <SzIcon variant="bold" icon="arrow-down-1" />
                              }
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={`${value[0]}`}>
                              <Card.Body as={Row}>
                                {value[1].length === 0 &&
                                  <DataNotReady />
                                }
                                {value[1].map((doc: any, docKey: any) => {
                                  return (
                                    <Col
                                      onClick={() => handleClick(doc)}
                                      sm="3"
                                      className="text-center gaia-detail-result--documents-container--doc"
                                    >
                                      <img
                                        className="gaia-detail-result--documents-container--doc-icon"
                                        alt=""
                                        src={getFileIcon(getDocumentTitle(doc))}
                                      />
                                      <span className="gaia-detail-result--documents-container--doc-title">
                                        {getDocumentTitle(doc)}
                                      </span>
                                    </Col>
                                  );
                                })
                                }
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        </Accordion>
                      );
                    })
                    }
                  </>
                }
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <Accordion defaultActiveKey="0">
          <Card className="container-bordered">
            <Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => setVicrDocumentOpen(!vicrDocumentOpen)}>
              {t("report:vicrDocuments")}
              {documentOpen ?
                <SzIcon variant="bold" icon="arrow-up-1" /> :
                <SzIcon variant="bold" icon="arrow-down-1" />
              }
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body as={Row}>
                {!props.vicrDocuments &&
                  <DataNotReady />
                }
                {props.vicrDocuments && props.vicrDocuments.length === 0 &&
                  <DataNotReady />
                }
                {props.vicrDocuments && props.vicrDocuments.map((doc: any) => {
                  return (
                    <Col
                      onClick={() => handleClick(doc)}
                      sm="3"
                      className="text-center gaia-detail-result--documents-container--doc"
                    >
                      <img
                        className="gaia-detail-result--documents-container--doc-icon"
                        alt=""
                        src={getFileIcon(getDocumentTitle(doc))}
                      />
                      <span className="gaia-detail-result--documents-container--doc-title">
                        {getDocumentTitle(doc)}
                      </span>
                    </Col>
                  );
                })
                }
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <Accordion defaultActiveKey="0">
          <Card className="container-bordered">
            <Accordion.Toggle
              as={Card.Header}
              eventKey="0"
              onClick={() => setItvDocumentOpen(!itvDocumentOpen)}
            >
              {t("report:itvDocumentsReports")}
              {itvDocumentOpen ?
                <SzIcon variant="bold" icon="arrow-up-1" /> :
                <SzIcon variant="bold" icon="arrow-down-1" />
              }
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body as={Row}>
                {!props.itvDocuments &&
                  <DataNotReady />
                }
                {props.itvDocuments && props.itvDocuments.length === 0 &&
                  <DataNotReady />
                }
                {props.itvDocuments && props.itvDocuments.map((doc: any) => {
                  return (
                    <Col
                      onClick={() => handleClick(doc)}
                      sm="3"
                      className="text-center gaia-detail-result--documents-container--doc"
                    >
                      <img
                        className="gaia-detail-result--documents-container--doc-icon"
                        alt=""
                        src={getFileIcon(getDocumentTitle(doc))}
                      />
                      <span className="gaia-detail-result--documents-container--doc-title">
                        {getDocumentTitle(doc)}
                      </span>
                    </Col>
                  );
                })
                }
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </>
    );
  }
};

export const getDocumentTitle =
  (document: { "cmis:name"?: string, "cmis:contentStreamFileName"?: string, "cm:title"?: string }): string => {
    if (document["cmis:name"] && document["cmis:name"].length > 0) {
      return document["cmis:name"];
    } else if (document["cmis:contentStreamFileName"] && document["cmis:contentStreamFileName"].length > 0) {
      return document["cmis:contentStreamFileName"];
    } else if (document["cm:title"] && document["cm:title"].length > 0) {
      return document["cm:title"];
    }
    return i18next.t("no-title-document");
  };

const mapDispatchToProps = (dispatch: any) => ({
  ...bindActionCreators(SearchActions, dispatch),
});

export default connect(null, mapDispatchToProps)(Documents);
