import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import { SzButton } from "react-theme-components";
import { bindActionCreators } from "redux";
import { config } from "../../config";
import { layers, mapType3D, sceneLayers } from "../../config/constants";
import {
  createBVCollectorLayer,
  createBVLayer,
  createBVParcelLayer,
  createConformityAndMajorFaultTypeLayers,
  createParcelLayers,
  createPluLayers,
  createProjectLayers,
  createSignalLayers,
  createSiteIndustrielLayers,
  createAllSiteIndustrielLayers,
  initializeMap,
} from "../../manager/map";
import { highlightObjects, highlightObjectsScene, mapLoaderConfig } from "../../manager/map/loader";
import { Report as ReportService } from "../../services/Report";
import { ReportActions, SearchActions, UserActions } from "../../store/redux/actions";
import { IBV, IParcel, IPlu, IProject, IReport, ISignal, ISiteIndustriel } from "../../store/redux/types";
import "./map.scss";
import {store} from "../../store";
import {SEARCH} from "../../store/redux/constants";

function Map(props: any) {
  const elementRef: any = useRef();
  const reportLayers: {
    EPEUNotConform: IReport[],
    EUEPEPEUNotConform: IReport[],
    EUEPNotConform: IReport[],
    conform: IReport[],
    functioningNotConform: IReport[],
    notConformOther: IReport[],
    unknown: IReport[],
  } = {
    EPEUNotConform: [],
    EUEPEPEUNotConform: [],
    EUEPNotConform: [],
    conform: [],
    functioningNotConform: [],
    notConformOther: [],
    unknown: [],
  };

  const projectLayers: {
    confirmed: IProject[],
    suspecte: IProject[],
    travaux: IProject[],
  } = {
    confirmed: [],
    suspecte: [],
    travaux: [],
  };

  const siteIndustrielsLayers: {
    siteIndustrielConform: ISiteIndustriel[],
    siteIndustrielNotConform: ISiteIndustriel[],
    siteIndustrielNotConcerned: ISiteIndustriel[],
    siteIndustrielUnknown: ISiteIndustriel[],
    siteIndustrielActifSuivi: ISiteIndustriel[],
    siteIndustrielNotSuivi: ISiteIndustriel[],
    siteIndustrielFerme: ISiteIndustriel[],

  } = {
    siteIndustrielConform: [],
    siteIndustrielNotConform: [],
    siteIndustrielNotConcerned: [],
    siteIndustrielUnknown: [],
    siteIndustrielActifSuivi:[],
    siteIndustrielNotSuivi:[],
    siteIndustrielFerme:[],
  };

  const signalLayers: {
    free: ISignal[],
    harmful: ISignal[],
    noise: ISignal[],
    odour: ISignal[],
    overflow: ISignal[],
    rejection: ISignal[],
    road: ISignal[],
    work: ISignal[],
  } = {
    free: [],
    harmful: [],
    noise: [],
    odour: [],
    overflow: [],
    rejection: [],
    road: [],
    work: [],
  };

  const parcelLayers: {
    disconnected: IParcel[],
    regulated: IParcel[],
    unknown: IParcel[],
  } = {
    disconnected: [],
    regulated: [],
    unknown: [],
  };

  const pluLayers: {
    favorable: IPlu[],
    defavorable: IPlu[],
    inexistant: IPlu[],
  } = {
    favorable: [],
    defavorable: [],
    inexistant: [],
  };

  const bvParcelLayers: {
    greaterThan5: IBV[],
    between1and5: IBV[],
    lessthan1: IBV[],
  } = {
    greaterThan5: [],
    between1and5: [],
    lessthan1: [],
  };

  const bvCollectorLayers: {
    SAN: IBV[],
    CAA: IBV[],
    DES2B: IBV[],
    SAR: IBV[],
    CAB: IBV[],
    CLI: IBV[],
    ES1B: IBV[],
    MAR: IBV[],
    NL: IBV[],
    ANT: IBV[],
    BLR: IBV[],
    SUR: IBV[],
    NE: IBV[],
    ASN: IBV[],
    LAS: IBV[],
  } = {
    SAN: [],
    CAA: [],
    DES2B: [],
    SAR: [],
    CAB: [],
    CLI: [],
    ES1B: [],
    MAR: [],
    NL: [],
    ANT: [],
    BLR: [],
    SUR: [],
    NE: [],
    ASN: [],
    LAS: [],
  };

  const mapMaxDisplayedItems = 2000;
  const pluMaxDisplayedItems = 1000; // The backend does not respond with 5000
  const epeu = "EP dans EU";
  const euep = "EU dans EP";
  const euepepeu = "EU dans EP et EP dans EU";
  const switchButton = document.getElementById("switch-btn") as HTMLInputElement;

  const getReportsByConformity = () => {
    ReportService.getReportListPromise(props.filters, "conformityStatusDate", "desc", mapMaxDisplayedItems, 1)
      .then((res: { data: IReport[] }) => {
        return res.data.filter((item: IReport) =>
          item.position && item.position !== "" && item.position.length > 0,
        );
      })
      .then((data: IReport[]) => {
        reportLayers.conform = data.filter((item: IReport) => 0 === item.conformity);
        reportLayers.notConformOther = data.filter((item: IReport) =>
          1 === item.conformity && epeu !== item.majorFaultType &&
          euep !== item.majorFaultType && euepepeu !== item.majorFaultType,
        );
        reportLayers.EPEUNotConform = data.filter((item: IReport) =>
          1 === item.conformity && epeu === item.majorFaultType);
        reportLayers.EUEPNotConform = data.filter((item: IReport) =>
          1 === item.conformity && euep === item.majorFaultType);
        reportLayers.EUEPEPEUNotConform = data.filter((item: IReport) =>
          1 === item.conformity && euepepeu === item.majorFaultType);
        reportLayers.functioningNotConform = data.filter((item: IReport) => 2 === item.conformity);
        reportLayers.unknown = data.filter((item: IReport) => 3 === item.conformity);
      },
      ).then(() => {
        props.getMapPreferences().then().then((data: any) => {
          createConformityAndMajorFaultTypeLayers(
            reportLayers,
            data.visibleLayers,
          );
        });
      });
  };

  const getProjectsByStatus = () => {
    ReportService.getProjectListPromise(
      props.projectFilters,
      props.projectSortField,
      props.projectSortDirection,
      mapMaxDisplayedItems,
      1,
    ).then((res: { data: IProject[] }) => {
      return res.data.filter((item: IProject) =>
        item.longitude !== null && item.latitude !== null,
      );
    }).then((data: IProject[]) => {
      projectLayers.confirmed = data.filter((item: IProject) =>
        "Confirmed" === item.status,
      );
      projectLayers.suspecte = data.filter((item: IProject) =>
        "Possible" === item.status,
      );
      projectLayers.travaux = data.filter((item: IProject) =>
        "Construction" === item.status,
      );
    },
    ).then(() => {
      createProjectLayers(
        projectLayers,
        props.mapPreferences.visibleLayers ? props.mapPreferences.visibleLayers : [],
      );
    });
  };

  const getBvParcels = () => {
    ReportService.getBvParcelListPromise(
      props.parcelFilters,
    ).then((res: { data: IBV[] }) => {
      bvParcelLayers.lessthan1 = res.data.filter((item: IBV) =>
        "less-than-1" === item.surfaceDeconnecteParcel,
      );
      bvParcelLayers.between1and5 = res.data.filter((item: IBV) =>
        "between-1-and-5" === item.surfaceDeconnecteParcel,
      );
      bvParcelLayers.greaterThan5 = res.data.filter((item: IBV) =>
        "greater-than-5" === item.surfaceDeconnecteParcel,
      );
    },
    ).then(() => {
      createBVParcelLayer(
        bvParcelLayers,
        props.mapPreferences.visibleLayers ? props.mapPreferences.visibleLayers : [],
      );
    });
  };

  const getBvByCollector = () => {
    ReportService.getFilteredGeoserverBVListPromise(
      props.geoserverBVFilters,
      props.geoserverBVSortField,
      props.geoserverBVSortDirection,
      mapMaxDisplayedItems,
      1,
    ).then((res: { data: IBV[] }) => {
      bvCollectorLayers.SAN = res.data.filter((item: IBV) =>
        "SAN" === item.collecteur,
      );
      bvCollectorLayers.CAA = res.data.filter((item: IBV) =>
        "CAA" === item.collecteur,
      );
      bvCollectorLayers.DES2B = res.data.filter((item: IBV) =>
        "DES2B" === item.collecteur,
      );
      bvCollectorLayers.SAR = res.data.filter((item: IBV) =>
        "SAR" === item.collecteur,
      );
      bvCollectorLayers.CAB = res.data.filter((item: IBV) =>
        "CAB" === item.collecteur,
      );
      bvCollectorLayers.CLI = res.data.filter((item: IBV) =>
        "CLI" === item.collecteur,
      );
      bvCollectorLayers.ES1B = res.data.filter((item: IBV) =>
        "ES1B" === item.collecteur,
      );
      bvCollectorLayers.MAR = res.data.filter((item: IBV) =>
        "MAR" === item.collecteur,
      );
      bvCollectorLayers.NL = res.data.filter((item: IBV) =>
        "NL" === item.collecteur,
      );
      bvCollectorLayers.ANT = res.data.filter((item: IBV) =>
        "ANT" === item.collecteur,
      );
      bvCollectorLayers.BLR = res.data.filter((item: IBV) =>
        "BLR" === item.collecteur,
      );
      bvCollectorLayers.SUR = res.data.filter((item: IBV) =>
        "SUR" === item.collecteur,
      );
      bvCollectorLayers.NE = res.data.filter((item: IBV) =>
        "NE" === item.collecteur,
      );
      bvCollectorLayers.ASN = res.data.filter((item: IBV) =>
        "ASN" === item.collecteur,
      );
      bvCollectorLayers.LAS = res.data.filter((item: IBV) =>
        "LAS" === item.collecteur,
      );
    },
    ).then(() => {
      createBVCollectorLayer(
        bvCollectorLayers,
        props.mapPreferences.visibleLayers ? props.mapPreferences.visibleLayers : [],
      );
    });
  };

  const getSignalsByCategory = () => {
    ReportService.getSignalListPromise(
      props.signalFilters,
      props.signalSortField,
      props.signalSortDirection,
      mapMaxDisplayedItems,
      1,
    ).then((res: { data: ISignal[] }) => {
      return res.data.filter((item: ISignal) =>
        item.longitude !== null && item.latitude !== null,
      );
    }).then((data: ISignal[]) => {
      signalLayers.free = data.filter((item: ISignal) =>
        "SIGNAL_ON_FREE" === item.category,
      );
      signalLayers.harmful = data.filter((item: ISignal) =>
        "SIGNAL_ON_HARMFUL" === item.category,
      );
      signalLayers.noise = data.filter((item: ISignal) =>
        "SIGNAL_ON_NOISE" === item.category,
      );
      signalLayers.odour = data.filter((item: ISignal) =>
        "SIGNAL_ON_ODOUR" === item.category,
      );
      signalLayers.overflow = data.filter((item: ISignal) =>
        "SIGNAL_ON_OVERFLOW" === item.category,
      );
      signalLayers.rejection = data.filter((item: ISignal) =>
        "SIGNAL_ON_REJECTION" === item.category,
      );
      signalLayers.road = data.filter((item: ISignal) =>
        "SIGNAL_ON_ROAD" === item.category,
      );
      signalLayers.work = data.filter((item: ISignal) =>
        "SIGNAL_ON_WORK" === item.category,
      );
    },
    ).then(() => {
      createSignalLayers(
        signalLayers,
        props.mapPreferences.visibleLayers ? props.mapPreferences.visibleLayers : [],
      );
    });
  };

  const getSiteIndustriels = () => {
    ReportService.getSiteIndustrielListPromise(
      props.siteIndustrielFilters,
      props.siteIndustrielSortField,
      props.siteIndustrielSortDirection,
      mapMaxDisplayedItems,
      1,
    ).then((res: { data: ISiteIndustriel[] }) => {
      console.log('getSiteIndustriels',res.data)
      return res.data;
    }).then((data: ISiteIndustriel[]) => {
          siteIndustrielsLayers.siteIndustrielConform = data.filter((item: ISiteIndustriel) =>
              "Conforme" === item.siteIndustrielConformity,
          );
          siteIndustrielsLayers.siteIndustrielNotConform = data.filter((item: ISiteIndustriel) =>
              "Non Conforme" === item.siteIndustrielConformity,
          );
          siteIndustrielsLayers.siteIndustrielNotConcerned = data.filter((item: ISiteIndustriel) =>
              "Non concerné" === item.siteIndustrielConformity,
          );
          siteIndustrielsLayers.siteIndustrielUnknown = data.filter((item: ISiteIndustriel) =>
              null === item.siteIndustrielConformity,
          );
          //Site industriel actif et suivi (vert)
          siteIndustrielsLayers.siteIndustrielActifSuivi = data.filter((item: ISiteIndustriel) =>
              null == item.dateFinSuivi,
          );
          //Site industriel non suivi actuellement (gris clair)
          siteIndustrielsLayers.siteIndustrielNotSuivi = data.filter((item: ISiteIndustriel) =>
              null != item.dateFinSuivi && 'Fermeture du site' == item.causeFinSuivi,
          );
          //Site industriel fermé actuellement (gris foncé)
          siteIndustrielsLayers.siteIndustrielFerme = data.filter((item: ISiteIndustriel) =>
              null != item.dateFinSuivi && 'Fermeture du site' != item.causeFinSuivi,

          );
        },
    ).then(() => {
      /*createAllSiteIndustrielLayers(
        siteIndustrielsLayers,
        props.mapPreferences.visibleLayers ? props.mapPreferences.visibleLayers : [],
      );*/

    },
    );
  };

  const getParcels = () => {
    ReportService.getParcelListPromise(
      props.parcelFilters,
      props.parcelSortField,
      props.parcelSortDirection,
      mapMaxDisplayedItems,
      1,
    ).then((res: { data: IParcel[] }) => {
      parcelLayers.disconnected = res.data.filter((item: IParcel) =>
        "DECONNECTE" === item.type,
      );
      parcelLayers.regulated = res.data.filter((item: IParcel) =>
        "REGULE" === item.type,
      );
      parcelLayers.unknown = res.data.filter((item: IParcel) =>
        "INCONNU" === item.type,
      );
    },
    ).then(() => {
      createParcelLayers(
        parcelLayers,
        props.mapPreferences.visibleLayers ? props.mapPreferences.visibleLayers : [],
      );
    },
    );
  };

  const getPlu = () => {
    ReportService.getPluListPromise(
      props.pluFilters,
      props.pluSortField,
      props.pluSortDirection,
      pluMaxDisplayedItems,
      1,
    ).then((res: { data: IPlu[] }) => {
      pluLayers.favorable = res.data.filter((item: IPlu) =>
        "Favorable" === item.gepCritTO,
      );
      pluLayers.defavorable = res.data.filter((item: IPlu) =>
        "Défavorable" === item.gepCritTO,
      );
      pluLayers.inexistant = res.data.filter((item: IPlu) =>
        "Inexistant" === item.gepCritTO,
      );
    },
    ).then(() => {
      createPluLayers(
        pluLayers,
        props.mapPreferences.visibleLayers ? props.mapPreferences.visibleLayers : [],
      );
    },
    );
  };

  useEffect(() => {
    getReportsByConformity();
  }, [props.filters, props.sortField, props.sortDirection]);

  useEffect(() => {
    if (props.displayProjectLayers) {
      getProjectsByStatus();
    }
  }, [props.projectFilters, props.projectSortField, props.projectSortDirection]);

  useEffect(() => {
    if (props.displaySignalLayers) {
      getSignalsByCategory();
    }
  }, [props.signalFilters, props.signalSortField, props.signalSortDirection]);

  useEffect(() => {
    if (props.displaySiteIndustrielLayers) {
      getSiteIndustriels();
    }
  }, [
    props.siteIndustrielFilters,
    props.siteIndustrielSortField,
    props.siteIndustrielSortDirection,
  ]);

  useEffect(() => {
    if (props.displayBVLayers) {
      getBvByCollector();
      getBvParcels();
    }
  }, [
    props.geoserverBVFilters,
    props.geoserverBVSortField,
    props.geoserverBVSortDirection,
  ]);

  useEffect(() => {
    if (props.displayParcelLayers) {
      getParcels();
    }
  }, [
    props.parcelFilters,
    props.parcelSortField,
    props.parcelSortDirection,
  ]);

  useEffect(() => {
    if (props.displayPluLayers) {
      getPlu();
    }
  }, [
    props.pluFilters,
    props.pluSortField,
    props.pluSortDirection,
  ]);

  const handleShowProjectDetail = () => {
    document.addEventListener("clickedProject", (e: any) => {
      e.stopPropagation();
      const projectId = e.detail.projectId;
      props.showProjectDetail(projectId);
    });
  };

  const handleShowSignalDetail = () => {
    document.addEventListener("clickedSignal", (e: any) => {
      e.stopPropagation();
      const signalId = e.detail.signalId;
      props.showSignalDetail(signalId);
    });
  };

  const handleShowSiteIndustrielDetail = () => {
    document.addEventListener("clickedSiteIndustriel", (e: any) => {
      e.stopPropagation();
      const idCD92 = e.detail.idCD92;
      props.showSiteIndustrielDetail(idCD92);
    });
  };

  const handleShowGeoserverBVDetail = () => {
    document.addEventListener("clickedBV", (e: any) => {
      e.stopPropagation();
      const idBV = e.detail.idBV;
      props.showGeoserverBVDetail(idBV);
    });
  };

  const handleShowDetail = () => {
    document.addEventListener("clickedObjects", (e: any) => {
      const connectionObject = e.detail.clickedObjects[0];
      if (
        connectionObject.id !== undefined &&
        typeof connectionObject.id !== "undefined" &&
        typeof connectionObject.layerId !== "undefined"
      ) {
        const layerName = connectionObject.layerId.replace(/ /g, "_");
        const indexName = layers[layerName];
        if (typeof indexName !== "undefined") {
          props.showDetailObject(connectionObject.id, layers[layerName]);
        }
      }
    });
  };

  // Switches the view from 2D to 3D and vice versa
  const switchView = () => {
    const is3D = mapLoaderConfig.activeView.type === "3d";
    const activeViewpoint = mapLoaderConfig.activeView.viewpoint.clone();

    // remove the reference to the container for the previous view
    mapLoaderConfig.activeView.container = null;

    if (is3D) {
      // if the input view is a SceneView, set the viewpoint on the
      // mapView instance. Set the container on the mapView and flag
      // it as the active view
      mapLoaderConfig.mapView.viewpoint = activeViewpoint;
      mapLoaderConfig.mapView.container = mapLoaderConfig.container;
      mapLoaderConfig.activeView = mapLoaderConfig.mapView;
      if (props.shownDetailId && props.shownDetailItem) {
        setTimeout(() => {
          const layerName = Object.keys(layers).find((key) => layers[key] === props.shownDetailItem["index:name"]);
          highlightObjects([{ layerName, objectId: props.shownDetailId as unknown as string }]);
        }, 2000);
      }
      switchButton.value = "3D";
    } else {
      mapLoaderConfig.sceneView.viewpoint = activeViewpoint;
      mapLoaderConfig.sceneView.container = mapLoaderConfig.container;
      mapLoaderConfig.activeView = mapLoaderConfig.sceneView;
      mapLoaderConfig.activeView.when(() => {
        if (props.shownDetailId && props.shownDetailItem) {
          setTimeout(() => {
            const sceneLayerName = Object.keys(sceneLayers).find(
              (key) => sceneLayers[key].includes(props.shownDetailItem["index:name"]),
            );
            highlightObjectsScene([{ layerName: sceneLayerName, objectId: props.shownDetailId as unknown as string }]);
          }, 2000);
        }
      });
      switchButton.value = "2D";
    }
  };

  const handleFullscreen = () => {
    props.displayMapFullScreen(!props.isDisplayedFullscreen);
  };

  useEffect(() => {
    if (props.logged) {
      store.dispatch({payload: {flag: {loading: true}}, type: SEARCH.LOADING});
      props.getGeoserverBV(props.geoserverBVsColors).then().then((geoserverBVs: any) => {
        props.getMapPreferences().then((data: any) => {
          initializeMap(
            elementRef.current,
            reportLayers,
            projectLayers,
            signalLayers,
            siteIndustrielsLayers,
            parcelLayers,
            pluLayers,
            bvCollectorLayers,
            props.defaultCoordinates,
            props.setMapPosition,
            props.displayBVLayers,
            props.displayProjectLayers,
            props.displaySignalLayers,
            props.displaySiteIndustrielLayers,
            props.displayParcelLayers,
            props.displayPluLayers,
            data.baseMap,
            data.forcedLayers,
            data.visibleLayers,
            props.itemId,
            geoserverBVs.data,
            bvParcelLayers,
            pluLayers,
            props.displaySmartshape,
          );
          getReportsByConformity();
          if (props.displayProjectLayers) {
            getProjectsByStatus();
          }

          if (props.displaySignalLayers) {
            getSignalsByCategory();
          }

          if (props.displaySiteIndustrielLayers) {
            getSiteIndustriels();
          }
          if (props.displayParcelLayers) {
            getParcels();
          }
          if (props.displayBVLayers) {
            getBvParcels();
            getBvByCollector();
          }
          if (props.displayPluLayers) {
            getPlu();
          }
          handleShowDetail();
          handleShowProjectDetail();
          handleShowSignalDetail();
          handleShowSiteIndustrielDetail();
          handleShowGeoserverBVDetail();
        });
      }).then(()=>{store.dispatch({payload: {flag: {loading: false}}, type: SEARCH.LOADING});})
          .catch((error:Error) => {
            store.dispatch({payload: {flag: {loading: false}}, type: SEARCH.LOADING});
            console.log(error);
          });
    }
  }, []);

  useEffect(() => {
    props.getGeoserverBV(props.geoserverBVsColors).then().then((geoserverBVs: any) => {
      props.getMapPreferences().then().then((data: any) => {
        if (props.displayBVLayers) {
          createBVLayer(geoserverBVs.data, data.visibleLayers);
        }
      });
    });
  }, [props.geoserverBVsColors]);

  return (
    <>
      <div id="viewDiv" ref={elementRef} />
      <div
        id="save-view"
        className="esri-widget esri-widget--button esri-widget esri-interactive"
        title="Sauvegarder la vue"
      >
        <span className="esri-icon-upload" />
      </div>
      <div
        id="load-view"
        className="esri-widget esri-widget--button esri-widget esri-interactive"
        title="Charger la vue"
      >
        <span className="esri-icon-download" />
      </div>
      <div id="infoDiv" className={config.MAP3D_ENABLED === "true" ? "" : "hidden"}>
        <input
          className="esri-component esri-widget--button esri-widget esri-interactive"
          type="button"
          id="switch-btn"
          onClick={switchView}
          value={mapLoaderConfig.activeView && mapLoaderConfig.activeView.type === mapType3D ? "2D" : "3D"}
        />
      </div>
      {props.showSmartshape && (
        <SzButton className="btn-map-fullscreen"
          onClick={handleFullscreen}
          type="button">
          <i className="material-icons icon-resize">
            {props.isDisplayedFullscreen ? "fullscreen_exit" : "fullscreen"}
          </i>
        </SzButton>
      )}
    </>
  );
}

const mapStateToProps = (state: any) => {
  return {
    geoserverBVs: state.report.geoserverBVs,
    geoserverBVsColors: state.report.geoserverBVsColors,
    bvParcel: state.report.bvParcel,
    bvParcelRates: state.report.bvParcelRates,
    mapPreferences: state.user.mapPreferences,
    isDisplayed3d: state.search.isDisplayed3d,
    isDisplayedFullscreen: state.search.isMapDisplayedFullscreen,
    logged: state.user.logged,
    project: state.report.projects,
    projectFilters: state.report.projectFilters,
    projectSortDirection: state.report.projectSortDirection,
    projectSortField: state.report.projectSortField,
    signalFilters: state.report.signalFilters,
    signalSortDirection: state.report.signalSortDirection,
    signalSortField: state.report.signalSortField,
    siteIndustrielFilters: state.report.siteIndustrielFilters,
    siteIndustrielSortDirection: state.report.siteIndustrielSortDirection,
    siteIndustrielSortField: state.report.siteIndustrielSortField,
    geoserverBVFilters: state.report.geoserverBVFilters,
    geoserverBVSortDirection: state.report.geoserverBVSortDirection,
    geoserverBVSortField: state.report.geoserverBVSortField,
    parcelFilters: state.report.parcelFilters,
    parcelSortDirection: state.report.parcelSortDirection,
    parcelSortField: state.report.parcelSortField,
    pluFilters: state.report.pluFilters,
    pluSortDirection: state.report.pluSortDirection,
    pluSortField: state.report.pluSortField,
    shownDetailId: state.search.detail.shownDetailId,
    shownDetailItem: state.search.detail.shownDetailItem,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  ...bindActionCreators({ ...SearchActions, ...UserActions, ...ReportActions }, dispatch),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Map);
